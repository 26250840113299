(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/definitions/powerplay.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/definitions/powerplay.js');
"use strict";

let makeXpertSizes;
let productIds;
let EventTypeId;
let constants;
let brandMapping;
if (svs.isServer) {
  constants = require('../constants.es6');
  makeXpertSizes = require('../utils/make-xpert-sizes.es6');
  productIds = require('trinidad-core').core.components.require('utils', 'products').productIds;
  EventTypeId = require('../../../../../sportinfo/common/assets/javascripts/constants.es6').EventTypeId;
  brandMapping = require('../../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6');
} else {
  makeXpertSizes = svs.components.tipsen.engine.utils.makeXpertSizes;
  productIds = svs.utils.products.productIds;
  EventTypeId = svs.components.sportinfo.common.constants.EventTypeId;
  constants = svs.components.tipsen.engine.constants;
  brandMapping = svs.components.lbUtils.brandMapping.fn;
}
const powerplayDefinition = {
  competitions: [],
  outcomes: {
    eventTypeId: EventTypeId.EVENT_1X2,
    outcomeCount: 3,
    outcomeGroupCount: 1,
    outcomeHeatmap: {}
  },
  rSystems: [],
  uSystems: [],
  reduction: [],
  systems: [constants.systems.SYSTEM_MSYS],
  betAmounts: [1, 2, 3, 5, 10],
  maxBetAmount: 10,
  weeks: [1],
  sportkryssFactors: [0],
  sportkryssBase: 10,
  addonPixSize: [0],
  maxSystemSize: 4374,
  drawProductIds: [productIds.POWERPLAY],
  xpertMsys: makeXpertSizes(8, 8, 2, 972),
  xpertMsysSpecialValues: [16, 24, 32, 36, 64, 81, 96, 192, 243],
  quickPlayPixSizes: [16, 48, 72, 108, 144, 192, 216, 384],
  displayName: brandMapping.getProductDisplayName(productIds.POWERPLAY),
  brandName: brandMapping.getSiteBrand(productIds.POWERPLAY),
  brandLogo: brandMapping.getProductBrand(productIds.POWERPLAY),
  getDisplayNameByDraw: draw => brandMapping.getProductDisplayNameByDraw(draw, productIds.POWERPLAY),
  getBrandNamesByDraw: draw => brandMapping.getProductBrandsByDraw(draw, productIds.POWERPLAY),
  getBrandLogoByDraw: draw => brandMapping.getProductBrandByDraw(draw, productIds.POWERPLAY),
  getBrandMenuColorByDraw: draw => brandMapping.getProductBrandMenuColorByDraw(draw, productIds.POWERPLAY),
  maxExternalSystemSize: 4374,
  features: {
    marketplace: true,
    singles: true
  },
  getRelatedProduct: () => null,
  getTransfer: () => null,
  pixSystems: [],
  minGroupAmount: 10,
  minStake: 1,
  hasGuaranteedJackpot: false,
  hasJackpot: false,
  tipsinfoSupport: [constants.TipsinfoTypes.Odds, constants.TipsinfoTypes.StartOdds, constants.TipsinfoTypes.SvenskaFolket, constants.TipsinfoTypes.Matchstart, constants.TipsinfoTypes.Favourites, constants.TipsinfoTypes.Mutuals, constants.TipsinfoTypes.Analys, constants.TipsinfoTypes.Ministat, constants.TipsinfoTypes.Compressed],
  tipsinfoSupportResult: [constants.TipsinfoTypes.Odds, constants.TipsinfoTypes.SvenskaFolket, constants.TipsinfoTypes.Favourites],
  tipsinfoSupportMyBets: [constants.TipsinfoTypes.Odds, constants.TipsinfoTypes.SvenskaFolket, constants.TipsinfoTypes.Favourites],
  loadDrawsApiPath: 'powerplay',
  moduleEngine: 'powerplay',
  defaultCouponValues: {
    betAmount: 1,
    pixSize: 72,
    reduceModeCount: undefined
  },
  pixType: '1X2',
  pixSystem: constants.PixSystem.Xperten,
  externalChunkSize: {
    SROW: 1000,
    MSYS: 100,
    SROW_MARKETPLACE_GROUP: 1000,
    MSYS_MARKETPLACE_GROUP: 100 
  },
  couponSettings: [constants.coupon.Compressed],
  maxWagerAmount: 0,
  maxSelectionOutcomeGroup: 0
};
if (svs.isServer) {
  module.exports = powerplayDefinition;
} else {
  setGlobal('svs.components.tipsen.engine.definitions.powerplay', powerplayDefinition);
}

 })(window);